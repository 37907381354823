.auth-background {
  @apply fixed right-0 min-h-[100vh] w-full bg-cover opacity-90 bg-[url(/login.png)] bg-[70%_0];

  &:after {
    @apply content-[''] absolute -z-10 bottom-0 left-0 h-3/4 w-full bg-cover bg-gradient-to-b from-transparent to-brand-neutral-800;
  }
}

.auth-header-wrapper {
  @apply relative flex bg-brand-neutral-800 bg-opacity-80 rounded-full p-1 font-bold text-white text-2xl;
}

.auth-header-item {
  @apply relative flex-1 rounded-full py-2 px-5 text-base leading-7;
}

.auth-header-item-pro {
  @apply auth-header-item flex gap-2 items-center justify-center;
}

.auth-header-transition {
  @apply absolute transition-all duration-300 bg-white rounded-full;
}

.auth-header-item-pro-chip {
  @apply uppercase text-mini px-2 py-1 bg-primary rounded-3xl text-white leading-normal mix-blend-normal;
}

.auth-sso-button {
  @apply capitalize #{!important};
}
