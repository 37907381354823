.btn {
  @apply my-3 h-10;

  &:disabled {
    @apply cursor-not-allowed opacity-50;
  }

  &.btn-link {
    @apply text-brand-neutral-300 font-bold hover:bg-brand-neutral-700 hover:bg-opacity-80 box-border;

    &.active {
      @apply bg-brand-neutral-700;
    }
  }

  &.btn-light {
    @apply border-none bg-brand-neutral-800 hover:bg-brand-neutral-700 hover:bg-opacity-80 text-white;
  }

  &.btn-danger {
    @apply h-10 border-[1px] border-danger text-danger md:my-auto;
  }

  &.btn-outline {
    @apply w-full text-brand-neutral-300 text-sm h-10 rounded-3xl flex justify-center items-center mt-4 mx-auto border-[1px] hover:bg-brand-neutral-700 hover:bg-opacity-80 hover:border-opacity-50;
  }

  &.btn-sm {
    @apply h-8 text-xs px-2;
  }
}

.back-button-icon {
  @apply text-4xl bg-brand-neutral-950 hover:bg-brand-neutral-800 rounded-full border border-brand-neutral-700 h-10 w-10 aspect-square;
}

.btn-loading.btn-primary:hover {
  @apply bg-primary;
}
