form {
  @apply font-thin;

  .select-wrapper {
    @apply w-full;

    .select-label {
      @apply block text-sm uppercase my-3 2xl:my-5 2xl:text-base;
    }

    .select-input {
      @apply mt-5 items-center relative w-full grid;

      select {
        @apply w-full row-start-1 col-start-1 px-3 leading-10 appearance-none bg-brand-neutral-700 border border-brand-neutral-600 rounded-md;
      }

      svg {
        @apply text-4xl -rotate-90 pointer-events-none z-10 right-1 relative col-start-1 row-start-1 self-center justify-self-end;
      }

      .select-description {
        @apply text-xs 2xl:text-sm px-2 mt-1 text-brand-neutral-300;
      }
    }
  }

  .checkbox-wrapper {
    @apply block py-1 cursor-pointer;

    .checkbox-label {
      @apply block text-sm uppercase my-3 2xl:my-5 2xl:text-base;
    }

    .checkbox-input {
      @apply flex w-full items-center relative;

      input[type='checkbox'] {
        @apply p-2 appearance-none bg-brand-neutral-800 border border-brand-neutral-700  checked:bg-primary checked:border-primary rounded checked:bg-input-checkbox-checked;
      }

      .checkbox-description {
        @apply text-xs 2xl:text-sm px-2 text-brand-neutral-300;
      }
    }
  }
}

.checkbox-as-chip {
  @apply text-brand-neutral-300 px-3 py-1.5 border inline-block rounded-full me-2 my-1 text-sm font-light cursor-pointer relative border-brand-neutral-300;

  &:hover {
    @apply border-white text-white;
  }

  &.active {
    @apply border-primary bg-primary text-white;
  }

  input {
    @apply absolute w-full h-full opacity-0 cursor-pointer;
  }
}

.input-wrapper {
  @apply w-full grid;

  input,
  textarea {
    @apply w-full px-3 leading-10 rounded-lg bg-brand-neutral-800 outline-none border border-brand-neutral-600 placeholder:text-sm;

    &:read-only {
      @apply text-brand-neutral-600 cursor-not-allowed;
    }

    &:focus {
      @apply border-brand-neutral-300;
    }

    &.has-error {
      @apply border-danger;
    }
  }

  textarea {
    @apply min-h-40 leading-6 pt-2 placeholder:text-[16px];
  }

  &.input-with-icon input {
    @apply row-start-1 col-start-1 pl-10 pr-3;
  }

  &.input-with-icon-end {
    & > input {
      @apply row-start-1 col-start-1 pl-3 pr-10;
    }

    & > button {
      @apply absolute z-50 text-lg flex items-center justify-center h-full aspect-square col-start-1 row-start-1 self-center justify-self-end;
    }
  }

  & > svg:not(.info) {
    @apply text-4xl pointer-events-none z-10 relative col-start-1 row-start-1 self-center justify-self-start;
  }

  & > svg {
    @apply left-3;
  }
}

.drag-file-element {
  @apply absolute w-full h-full top-0 left-0 right-0 bottom-0;
}
