.segment {
  @apply flex;
}

.segment-button {
  @apply cursor-pointer px-4;

  & > * {
    @apply w-full flex items-center justify-center pt-4;
  }

  &.inactive {
    @apply opacity-50 pb-[2px];
  }

  &.active {
    @apply opacity-100 border-b-2;
  }
}
