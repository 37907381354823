.card {
  .card-title {
    @apply title-h3;
  }

  p {
    @apply text-brand-neutral-300;
  }
}

.media-card {
  @apply flex flex-row justify-between relative;

  &.episodic {
    @apply border-b-brand-neutral-700 border-b-[1.5px] py-[12px] last:border-0;
  }

  .timeline-and-info {
    @apply flex flex-col max-w-full;

    &.locked {
      @apply cursor-pointer;
    }

    &.coming-soon {
      @apply opacity-70 cursor-not-allowed;
    }

    .media-info {
      @apply flex flex-col flex-1;

      .media-title {
        @apply text-sm font-semibold text-white pt-[3px];
      }

      .media-duration {
        @apply text-xs py-1 text-brand-neutral-300 leading-normal;
      }
    }
  }
}
