#layout {
  @apply h-full max-h-full;

  .content {
    @apply h-full flex flex-col relative pt-14 pb-[var(--player-mobile-h)] sm:pb-[var(--player-h)] sm:ml-[var(--navigation-tablet-w)] md:ml-[var(--navigation-w)];

    & > div {
      @apply min-h-[calc(100vh-var(--header-h))] md:min-h-[calc(100vh-var(--header-h)-var(--player-h))] w-full h-full max-w-7xl mx-auto flex flex-col pb-10 sm:pb-6;
    }
  }
}
