.modal-open {
  @apply overflow-hidden #{!important};
}

.modal {
  .modal-bg {
    @apply fixed top-0 right-0 z-[60] w-full h-full bg-brand-neutral-950/50 backdrop-blur-sm overscroll-y-none;
  }

  .modal-container {
    @apply max-h-[calc(100vh-10em)] z-[60] fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-brand-neutral-900 rounded-xl w-full overflow-y-auto;
    @apply sm:max-w-xl md:max-w-4xl max-sm:rounded-none;
    @apply transition-all duration-300 ease-out;

    .modal-header {
      @apply fixed top-0 z-30 w-full h-20 flex justify-center bg-brand-neutral-900/80 backdrop-blur-sm;
    }

    .modal-content {
      @apply max-sm:px-5 max-sm:h-full max-sm:flex max-sm:flex-col max-sm:justify-center;

      .swiper {
        @apply overflow-y-hidden;
      }
    }

    .modal-back-button {
      @apply absolute top-4 left-0 z-10 ms-5;
      @apply md:mt-4;
    }
  }
}
