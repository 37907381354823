.tab-list {
  @apply w-full mx-auto border-b border-brand-neutral-700 inline-flex gap-x-6 items-center text-sm;

  li {
    a,
    button {
      @apply block py-4 duration-150 font-medium text-brand-neutral-300 border-b-2 border-transparent;

      &:hover,
      &.selected {
        @apply border-secondary text-white;
      }

      &:disabled {
        @apply text-brand-neutral-400 opacity-50 cursor-not-allowed;
      }
    }
  }
}

.tab-block {
  @apply flex gap-5 bg-white rounded-lg p-5 mt-4;
}
