@use './auth.scss';
@use './block.scss';
@use './bottom-sheet.scss';
@use './button.scss';
@use './card.scss';
@use './chip.scss';
@use './grid.scss';
@use './header.scss';
@use './icon.scss';
@use './segment.scss';
@use './player.scss';
@use './form.scss';
@use './layout.scss';
@use './modal.scss';
@use './navigation.scss';
@use './notification.scss';
@use './popover.scss';
@use './sidebar.scss';
@use './swiper.scss';
@use './tab.scss';
@use './typo.scss';

:root {
  --header-h: 56px;

  --navigation-w: 15rem;
  --navigation-tablet-w: 13rem;

  --player-h: 110px;
  --player-mobile-h: 91px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  body > div:first-child,
  div#__next,
  div#__next > div {
    @apply h-full;
  }

  html,
  body {
    @apply font-sans bg-brand-neutral-950 text-white min-h-[100vh];
  }

  body {
    @apply overflow-hidden;
  }

  .slick-dots li button:before,
  .slick-dots li.slick-active button:before {
    color: white;
  }

  .slick-dots li.slick-active button:before {
    font-size: 10px;
  }

  .text-shadow {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }

  :root {
    --swiper-navigation-size: 88px;
    --rsbs-bg: #1f202b;
    --rsbs-handle-bg: #c0c1c5;
  }

  reach-portal {
    font-family: Arial;
  }
}

@layer components {
  .scrollable-hidden {
    @apply before:opacity-0 after:opacity-0;
  }

  .scrollable-bottom-shadow {
    @apply after:bottom-0 after:bg-gradient-to-t after:from-brand-neutral-900 after:content-[''] after:block after:h-[80px] after:w-full after:absolute after:rounded-md after:z-10 after:pointer-events-none after:transition-opacity after:opacity-100 after:duration-500 after:ease-out;
  }

  .scrollable-top-shadow {
    @apply before:top-0 before:bg-gradient-to-b before:from-brand-neutral-900 before:content-[''] before:block before:h-[80px] before:w-full before:absolute before:rounded-md before:z-10 before:pointer-events-none before:transition-opacity before:opacity-100 before:duration-500 before:ease-out;
  }

  .scrollable-top-dark-shadow {
    @apply after:bottom-0 after:bg-gradient-to-b after:from-brand-neutral-950 after:content-[''] after:block after:h-10 after:w-full after:absolute after:z-10 after:pointer-events-none after:transition-opacity after:opacity-100 after:duration-500 after:ease-out;
  }

  .scrollable-bottom-dark-shadow {
    @apply after:top-0 after:bg-gradient-to-t after:from-brand-neutral-900 after:content-[''] after:block after:h-20 after:w-full after:absolute after:z-10 after:pointer-events-none after:transition-opacity after:opacity-100 after:duration-500 after:ease-out;
  }

  .button-hide-media:hover .popover-hide-media {
    @apply block;
  }
}

.firebase-emulator-warning {
  @apply opacity-20;
}

.loader .title {
  @apply bg-brand-neutral-700 text-brand-neutral-700 rounded-md inline-block h-6 p-0 line-clamp-1 m-5 w-auto;
}

.loader p,
.loader h6 {
  @apply bg-brand-neutral-700 text-brand-neutral-700 rounded-md flex w-full h-4 line-clamp-1;
}

// Hides the left gradient of react-fast-marquee
.rfm-overlay::before {
  @apply hidden;
}

.img-square,
.img-square-sm {
  @apply aspect-square bg-brand-neutral-900 flex justify-center items-center relative rounded-md;

  img {
    @apply object-cover rounded-md;
  }
}

.img-rounded {
  @apply img-square rounded-full #{!important};
}

img::before {
  @apply absolute bottom-5 left-1/2 -translate-x-1/2 text-xs;
}
