.header {
  @apply fixed top-0 z-30 w-full h-14 flex justify-between bg-brand-neutral-950/80 backdrop-blur-sm;

  &.opaque {
    @apply bg-brand-neutral-950;
  }

  & > div {
    @apply relative;
  }
}
