.icon {
  width: 1em;
  height: 1em;
  @apply inline-block stroke-[0.5] fill-current stroke-current;

  &.icon-sm {
    @apply w-3 h-3;
  }

  &.icon-lg {
    @apply w-10 h-10;
  }
}
