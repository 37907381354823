.popover-wrapper {
  @apply flex justify-between items-start my-2 gap-2 relative;

  .popover-button {
    @apply cursor-pointer;
  }
}

.popover {
  @apply bg-brand-neutral-800 border border-brand-neutral-700 text-brand-neutral-300 rounded-md drop-shadow w-max z-50;

  ul {
    @apply space-y-2 py-2;

    li {
      @apply px-2;

      .icon {
        @apply w-4 h-4 text-brand-neutral-300 me-2;
      }

      a,
      button {
        @apply flex items-center w-full text-sm text-start py-2 ps-2 pe-4 rounded-md font-normal;

        &:hover,
        &.active {
          @apply bg-brand-neutral-700 text-white;
        }
      }

      &:last-child:not(:first-child) {
        @apply border-t border-brand-neutral-700 pt-2 pb-0;
      }

      &:last-child:first-child {
        @apply py-0;
      }
    }
  }
}

.popover-button:hover .popover {
  @apply flex;
}

.popover-sm {
  ul {
    @apply space-y-1 py-1;

    li {
      @apply px-1;

      .icon {
        @apply w-6 h-6;
      }

      a,
      button {
        @apply py-1 ps-1 pe-2;
      }

      &:last-child {
        @apply pt-1;
      }
    }
  }
}
