.notification {
  @apply fixed bottom-5 right-5 z-[60];

  &.notification-animated {
    @apply animate-[notification_400ms_ease-in-out];
  }

  & > div {
    @apply relative max-w-lg px-6 py-3 pt-5 rounded-lg bg-primary shadow text-white cursor-pointer;

    .notification-close {
      @apply text-xl absolute right-1 top-0 opacity-50 hover:opacity-100;
    }

    .notification-title {
      @apply font-medium;
    }

    .notification-message {
      @apply font-light;
    }
  }
}
